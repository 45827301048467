import React from "react";
import './image3.css';
import land1 from './images/Land 1.png'
import land2 from './images/Land 2.png'
import land3 from './images/Land 3.png'
const Image3=()=>{
    return(
        <div className="images">
            <img src={land1}/>
            <img src={land2}/>
            <img src={land3}/>
        </div>
    )
}

export default Image3;