import React from "react";

// import './navigation.css';

const Navigation =()=>{
    return(
        
            <ul className="list">
                  <li>
                      <a href="#">Home</a>
                  </li>
                  <li>
                      <a href="#story">Story</a>
                  </li>
                  <li>
                      <a href="#roadmap">Roadmap</a>
                  </li>
                  <li>
                      <a href="https://sageverse.gitbook.io/sageverse/overview/welcome-to-sageverse-tm" target="blank">whitepaper</a>
                  </li>
                  <li>
                      <a href="#token">marketplace</a>
                  </li>
              </ul>
        
    )
}

export default Navigation;