import React from "react";
import Heading from "../generalComponent/Heading";
import './section8.css';
import step from './images/step.png'
import step2 from './images/step2.png'

import Video from '../Section3/images/S3video.mp4'
import BottomImg from "../generalComponent/BottomImg";

const Section8=()=>{
    return(
        <div className="section8">
            <video id="background-video"  autoPlay loop muted>
             <source src={Video} type="video/mp4"/>
           </video>

            <div  className="section8_content">
                <Heading content='How TO Earn'></Heading>
               
                <div className="stepImg">
                <img src={step}></img>
                </div>
                <div className="stepImg2">
                <img src={step2}></img>
                </div>
                
            </div>
            <div className="bottom">
           <BottomImg></BottomImg>
           </div>
        </div>
    )

}

export default Section8;