import React from "react";
import Heading from "../generalComponent/Heading";
import './RoadMap.css';
import StockText from "../generalComponent/StockText";
import road1 from './images/1.png';
import road2 from './images/2.png';
import road3 from './images/3.png';
import road4 from './images/4.png';
import road5 from './images/5.png';
import road6 from './images/6.png';
import road7 from './images/7.png';
import dragon from './images/Dragon.png'
// import Heading from "../generalComponent/Heading";

const RoadMap=()=>{
    return(
        <div className="roadmap" id="roadmap">
            
            <div  className="roadmap_content">
            <Heading content='Roadmap'></Heading>
           
                <div className="roadmap_div">
                     <div>
                         <img src={road1}></img>
                         <img src={road2}></img>
                         <img src={road3}></img>
                         <img src={road4}></img>
                     </div>
                     <div className="dragon">
                        <img src={dragon}></img>
                     </div>
                     <div>
                        <img src={road5}></img>
                        <img src={road6}></img>
                        <img src={road7}></img>
                     </div>
                </div>



                <div className="roadmap_div_2">
                     <div>
                         <img src={road1}></img>
                         <img src={road5}></img>
                         <img src={road2}></img>
                         <img src={road6}></img>

                       
                     </div>
                     <div className="dragon">
                        <img src={dragon}></img>
                     </div>
                     <div>
                        <img src={road3}></img>
                        <img src={road7}></img>
                         <img src={road4}></img>
                        
                     </div>
                </div>
            </div>
                
            </div>
        
    )

}

export default RoadMap;