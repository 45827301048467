import React from "react";
import buttom from '../../images/buttom.png';
import './bottom.css';


const BottomImg =()=>{
    return(
        <div className="bottom">
<img src={buttom} alt='buttom'/>
        </div>
        
    )
}

export default BottomImg;