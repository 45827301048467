import React from "react";
import './footer.css';
import Heading from "../generalComponent/Heading";
import logo_img from './images/logo.png'
import line from './images/Line.png'
import link from './images/linkedin.png'
// import fb from './images/facebook.png'
import mail from './images/gmail.png'
// import telegram from './images/telegram.png'
import twiter from './images/twiter.png'
// import Video from './images/Fvideo.mp4'

const Footer=()=>{
    return(
        <div className="footer" id="footer">
              {/* <video id="background-video"  autoPlay loop muted>
             <source src={Video} type="video/mp4"/>
           </video> */}
            <div className="footer_content">
                <div className="logo_img">
                    <img src={logo_img}></img>
                </div>
                <div className="text_content">
                    <p>SageVerse™ Is a F2P, P2E "community collaboration" based MMORPG <br></br>
 with player-owned digital assets and cryptocurrency-based rewards powered by the Polygon blockchain.
</p>
                </div>
                <div className="flex_div">
                    <div>
                        <a href="#">Home</a>
                        <a href="#">About us</a>
                        <a href = "mailto: info@sageverse.net">Contact us</a>
                    </div>
                    <div>
                        <img src={line}></img>
                    </div>
                    <div>
                        <a href="#token">MarketPlace</a>
                        <a href="https://sageverse.gitbook.io/sageverse/overview/welcome-to-sageverse-tm" target="blank">White Paper</a>
                        
                    </div>
                </div>
                <Heading content='Join Our Community'></Heading>
                <div className="social_links">

                    <a href="https://www.tiktok.com/@sageverse_nft" target="blank"><img src={link}></img></a>
                    <a href="https://twitter.com/SageverseMedia" target="blank"><img src={twiter}></img></a> 
                    <a href="https://discord.gg/RVrbQN9Aph" target="blank"><img src={mail}></img></a>
                    {/* <a href=""><img src={telegram}></img></a>
                    <a href=""><img src={fb}></img></a> */}
                   
                
                    
                </div>
                <div>
                    <p>All contracts are owned by SageVerse L.l.c </p>
                </div>
                <div className="footer_nav">
                    <a href="#">About Us</a>
                    <a href="https://sageverse.gitbook.io/sageverse/sageverse-tm-technology-team-and-vision/disclaimer" target="blank"> Disclaimer</a>
                    <a href="#">Privacy Policy</a>
                    <a href="#">Terms of use</a>
                    <a href = "mailto: info@sageverse.net">Contact us</a>
                </div>

            </div>

        </div>
    )
}

export default Footer;