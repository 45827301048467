import React from "react";
import './section4.css';
import Heading from "../generalComponent/Heading";
import jade1 from './images/jade1.png'
import Button from "../generalComponent/Button";
import CarouselContainer from "./CasouselContainer";
import Frame from "../generalComponent/Frame";

const Section4 =()=>{
    return(
        <div className="section4">
            <div className="section4_content">
                <Heading content='Murim Community Jades'></Heading>
               
                <div className="section4_main">
                    <p>Mysterious jade tiles formed from the condensation of the world's Qi.<br/> 
They only come into existence before times of calamity, and make their way into the hands of those <br/>
who hear the call of destiny.
        </p>
            {/* <img src={jade1}></img> */}
              <div className="section4_frame">
              <Frame></Frame>
              </div>
             

            <CarouselContainer className='crousel'></CarouselContainer>
            
             <div className="last_part">

            
            {/* <Heading content="Blood Jade"></Heading> */}
            <p>The "Murim Community" is an independent sect in SageVerse™ that exist autonomously of the Five Great Sects.<br/> </p>
               <Button href="https://sageverse.gitbook.io/sageverse/sageverse-tm-economy/sageverse-tm-nfts/sageverse-tm-murim-jade-membership-nfts"></Button>
               </div>
                </div>
            </div>
        </div>
    )
}

export default Section4;