import React from "react";
import Heading from "../generalComponent/Heading";
import './section11.css';

import BottomImg from "../generalComponent/BottomImg";

const Section11 =()=>{
    return(
        <div className="section11">
            <div className="section11_inner">
                <Heading content='Faqs'></Heading>
                
                <div className="content">
                    <div className="my_div">
                    How much will it cost to mint Murim Membership NFT’s?
                    </div>
                    <div>
                        <p>White Jade - WL 90 Matic Public 180 Matic</p>
                        <p>Yellow Jade - WL 180 Matic Public 360 Matic</p>
                        <p>Green Jade - WL 360 Matic Public 720 Matic</p>
                        <p>Blue Jade - WL 720 Matic Public 1440 Matic</p>
                        <p>Blood Jade - WL 1440 Matic Public - 2880 Matic</p>
                    </div>
                    <div className="my_div">
                    What are Murim Membership Jades?
                    </div>
                    <div>
                        <p>Murim Membership Jade Nft’s grant access to the Murim Sect, an early supporter sect that has early access to SageVerse and contain different levels of utility  
</p>
                    </div>
                    <div className="my_div">
                    When is the SageVerse launch?
                    </div>
                    <div>
                        <p>Check out our Roadmap for project details.</p>
                    </div>
                    <div className="my_div">
                    How many Murim Jade Memberships exist on the 
Polygon blockchain?
                    </div>
                    <div>
                        <p>There are 27,000 Murim Jades in Total divided in to 5 collections.</p>
                    </div>
                    <div className="my_div">
                    Do I own the commercial rights to my Murim Jade 

                    </div>
                    <div>
                        <p>Yes, you have full commercial rights over your NFT!</p>
                    </div>
                    <div className="my_div">
                    Will there be a secondary marketplace to purchase 
Murim Jade Memberships?
                    </div>
                    <div>
                        <p>Yes. You will be able to purchase a Murim Jade Membership on the Opensea secondary 
market.</p>
                    </div>
                </div>
            </div>

            <div className="bottom">
           <BottomImg></BottomImg>
           </div>
        </div>
    )
}

export default Section11;