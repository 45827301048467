import React from "react";
import Button from "../generalComponent/Button";
import Heading from "../generalComponent/Heading";
import Paragraph from "../generalComponent/Pargraph";
import Image3 from "./Image3";
import Image2 from "./Image2";
import './section6.css';

import Video from '../Section3/images/S3video.mp4'
import BottomImg from "../generalComponent/BottomImg";

import land1 from '../Section6/images/Land 1.png'
const Section6 =()=>{
    return(
        <div className="section6">
              <video id="background-video"  autoPlay loop muted>
             <source src={Video} type="video/mp4"/>
           </video>
            <div className="section_content">
            <div className="heading">
            <Heading content='Lands'></Heading>
            

            </div>
             <p>SageVerse players will be able to purchase different types of land assets.<br></br>
These assets will serve as a home for the character in the Metaverse as well as a<br></br> 
place of commerce, training, and socialization and can provide passive income to owners through rental services.
</p>
            <div className="land_iamges">

            
            <div>
                <Image3></Image3>
            </div>
            <div>
                <Image2></Image2>
            </div>
            </div>
            <Button href="https://sageverse.gitbook.io/sageverse/sageverse-tm-economy/sageverse-tm-nfts/siheyaun-nfts"></Button>
            </div>

            <div className="bottom">
           <BottomImg></BottomImg>
           </div>
        </div>
    )
}

export default Section6;