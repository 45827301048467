import React from "react";
import './frame.css';

import rightFrame from './images/rightFrame.png';
import leftFrame from './images/leftFrame.png';

const Frame=()=>{
    return(
        <div className="frame">
            <div className="frame_inner">
               <img src={leftFrame}></img>
               <img src={rightFrame}></img>
            </div>

        </div>
    )
}

export default Frame;

