import React from "react";
import './section9.css'
import Heading from "../generalComponent/Heading";

import Information from "./Information";

import user1 from './images/user1.png'
import user2 from './images/user2.png'
import user3 from './images/user3.png'
import user4 from './images/user4.png'
import user5 from './images/user5.png'
import user6 from './images/user6.png'
import user7 from './images/user7.png'
import user8 from './images/user8.png'
import user9 from './images/user9.png'


const Section9=()=>{
    return(
        <div className="section9">
            <div className="section9_inner">
            <Heading content='Team Members'></Heading>
           
            <div className="founders">
                <div>
                    <Information user={user1} name='Black Tortoise Sage Ian' status='Creator And CEO' href1="mailto:earthchld1@gmail.com"></Information>
                </div>
                <div>
                <Information user={user8} name="Green Dragon Sage Edwin" status='Lead 2D Artist' href1="https://www.linkedin.com/mwlite/in/edwin-firmansyah-364047224
https://instagram.com/edwin.artwork?utm_medium=copy_link
https://www.facebook.com/edwin.x.outsider" ></Information>
                <Information user={user4} name="Red Phoenix Sage Maria" status='Lead 3D Model Developer' href1="
https://www.instagram.com/t_m_v_art/"></Information>
                <Information user={user2} name="White Tiger Sage James" status='Head Of Marketing' href1="https://twitter.com/J_clivenation?t=4VRwE9uc3xoFRen5Ag2q5Q&s=09"></Information>
                <Information user={user9} name="Fire Sage Ahmad" status='UI/UX And Web Developer' href1="https://www.linkedin.com/in/ahmad-jahangheer-669a2a196/"></Information>
                
               
               
                </div>
                <div>
                <Information user={user3} name="Metal Sage Fahad" status='Web 3.0 Developer' href1="https://www.linkedin.com/in/fahad-ashfaq-b7584512b/"></Information>
                <Information user={user5} name="Water Sage Vishal" status='Lead Game Developer' href1="https://www.linkedin.com/in/ineffable8055/"></Information>
                <Information user={user6} name='Earth Sage Aditya' status='Project Manager' href1="https://www.linkedin.com/in/aditya-vashishtha-5a4b31215/"></Information>
                <Information user={user7} name="Wood Sage Divasraj" status='Co-Developer' href1="https://www.linkedin.com/in/divasraj-vimal/"> </Information>
                
               

                </div>

            </div>


            <div className="founders2">
                <div>
                <Information user={user1} name='Black Tortoise Sage Ian' status='Creator And CEO' href1="mailto:earthchld1@gmail.com"></Information>
                </div>
                <div>
                <Information user={user8} name="Green Dragon Sage Edwin" status='Lead 2D Artist' href1="https://www.linkedin.com/mwlite/in/edwin-firmansyah-364047224
https://instagram.com/edwin.artwork?utm_medium=copy_link
https://www.facebook.com/edwin.x.outsider" ></Information>
                <Information user={user4} name="Red Phoenix Sage Maria" status='Lead 3D Model Developer' href1="
https://www.instagram.com/t_m_v_art/" ></Information>
                </div>
                <div>
                <Information user={user2} name="White Tiger Sage James" status='Head Of Marketing' href1="https://twitter.com/J_clivenation?t=4VRwE9uc3xoFRen5Ag2q5Q&s=09"></Information>
                <Information user={user9} name="Fire Sage Ahmad" status='UI/UX And Web Developer' href1="https://www.linkedin.com/in/ahmad-jahangheer-669a2a196/" ></Information>
                </div>
                <div>
                <Information user={user3} name="Metal Sage Fahad" status='Web 3.0 Developer' href1="https://www.linkedin.com/in/fahad-ashfaq-b7584512b/"></Information>
                <Information user={user5} name="Water Sage Vishal" status='Lead Game Developer' href1="https://www.linkedin.com/in/ineffable8055/"></Information>
                </div>
                <div>
                <Information user={user6} name='Earth Sage Aditya' status='Project Manager' href1="https://www.linkedin.com/in/aditya-vashishtha-5a4b31215/" ></Information>
                <Information user={user7} name="Wood Sage Divasraj" status='Co-Developer' href1="https://www.linkedin.com/in/divasraj-vimal/"> </Information>

                </div>

            </div>


            {/* <Founder className="founder"></Founder>
            <FourMembers></FourMembers>
            <ThreeMembers></ThreeMembers> */}
            </div>
          
        </div>
    )

}

export default Section9;