import React from "react";
import './information.css'
// import Link from "@material-ui/core/Link";
// import {BrowserRouter as Router, Link} from 'react-router-dom';



import link from './images/linkedin.png';
import fb from './images/facebook.png';
import mail from './images/gmail.png'

const Information=(props)=>{
    return(
        <div className="information">
           
            <img className="person" src={props.user}></img>
            <p>{props.name}</p>
            <h2>{props.status}</h2>
            {/* <div className="social_img">
            <a href={props.href1} className="linkedin" target="_blank"><img src={link}></img></a>
            <a href={props.href1} className="facebook" target="_blank"><img src={fb}></img></a>
            <a href={props.href1} className="mail" target="_blank"><img src={mail}></img></a>
            </div> */}
        </div>
    )
}

export default Information; 