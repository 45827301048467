import React from "react";
import { Carousel} from 'react-bootstrap';
import './crouselComponent.css';

// import character1 from './images/mixchart1.png'

import char1 from './images/char1.mp4';
import char2 from './images/char2.mp4';
import char3 from './images/char3.mp4';
import char4 from './images/char4.mp4';
import char5 from './images/char5.mp4';
import char6 from './images/char6.mp4';
import char7 from './images/char7.mp4';
import char8 from './images/char8.mp4';




const CarouselContainer=()=>{
    return(

        <Carousel indicators= 'false' className="crousel">
  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char1} type="video/mp4"/>
           </video>
    {/* <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char2} type="video/mp4"/>
           </video>

    {/* <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char3} type="video/mp4"/>
           </video>

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>

  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char4} type="video/mp4"/>
           </video>

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>

  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char5} type="video/mp4"/>
           </video>

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>

  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char6} type="video/mp4"/>
           </video>

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>

  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char7} type="video/mp4"/>
           </video>

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>

  <Carousel.Item>
  <video className="w-100"  autoPlay loop muted>
             <source src={char8} type="video/mp4"/>
           </video>

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
</Carousel>

    )
}

export default CarouselContainer;


