import React from "react";
import './section2.css'
import ThreeSteps from "./ThreeSteps";
import Frame from "../generalComponent/Frame";

const Section2=()=>{
    return(
        <div className="section2">
            <div className="section2_frame">
            <Frame></Frame>

            </div>
            

            <ThreeSteps></ThreeSteps>



        </div>
    )
}

export default Section2;