import React from "react";
import './section5.css'


import Heading from "../generalComponent/Heading";
import Button from "../generalComponent/Button";
import CarouselContainer from "./CasouselContainer";
import Frame from "../generalComponent/Frame";

const Section5=()=>{
    return(
        <div className="section5">
            <div className="section5_content">
            <Heading content='Characters'></Heading>
           
            
                <div className="section5_main">
                    <p>SageVerse character cards will be base level cards. Their uniqueness and rarity will be solely dependent upon<br></br> 
how players customize and develop their characters. More races and creatures coming soon.

        </p>
            {/* <img src={char1}></img> */}
            {/* <div className="section5_frame">
            <Frame></Frame>
            </div> */}
            
            <CarouselContainer className='crousel'></CarouselContainer>



             
            {/* <Heading content="Villager"></Heading> */}
            <div className="last_part">
            <Button href="https://sageverse.gitbook.io/sageverse/sageverse-tm-economy/sageverse-tm-nfts/character-nfts"></Button>
            </div>
            
               
                </div>

            </div>
        </div>
    )
}

export default Section5;