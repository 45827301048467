import React from "react";
import Heading from "../generalComponent/Heading";
import './section10.css';
import partner_0 from './images/partner_0.png';
import partner_1 from './images/partner_1.png';
import partner_2 from './images/partner_2.png';
import partner_3 from './images/partner_3.png';
// import Heading from "../generalComponent/Heading";\

import Frame from "../generalComponent/Frame";


const Section10 = ()=>{
    return(
        <div className="section10">
            <div className="section10_inner">

            
            <Heading content="Press and Partners"></Heading>

            <div className="partner_div">

                <a href="https://www.digitaljournal.com/pr/sageverse-is-developing-a-p2e-experience-that-finally-puts-gaming-first"><img src={partner_1}></img></a>
                <a><img src={partner_2}></img></a>
                <a href="https://dailycoin.com/sageverse-the-first-martial-arts-fantasy-metammorpg-changes-the-game-for-the-genre/"><img src={partner_3}></img></a>
                <a><img src={partner_0}></img></a>
                <a><img src={partner_0}></img></a>
                <a><img src={partner_0}></img></a>
                <a><img src={partner_0}></img></a>
                <a><img src={partner_0}></img></a>
                
            </div>

            

              {/* <div className="section10_frame">
              <Frame></Frame>
              </div> */}

    
            {/* <h1 className="coming">Coming Soon</h1> */}
            </div>
            
        </div>
    )
}

export default Section10;