import React from "react";
import './image3.css';
import land4 from './images/Land 4.png'
import land5 from './images/Land 5.png'
const Image2=()=>{
    return(
        <div className="images">
            <img src={land4}/>
            <img src={land5}/>
        </div>
    )
}

export default Image2;