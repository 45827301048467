import React from "react";
import Heading from "../generalComponent/Heading";
import './section7.css';
import chart from './images/chart.png';

import chart1 from './images/chart1.png';
import chart2 from './images/chart2.png';
import chart3 from './images/chart3.png';

const Section7=()=>{
    return(
        <div className="section7" id="token">
         <div className="section7_content">
             <Heading content='Tokenomics'></Heading>
             {/* <p className="my_para">In Game currencies<br/>
All currencies will be on POLYGON Block Chain</p> */}
             
             <div className="first_div">
           
                 <div className="text_part">
                     {/* <Heading content='Company Token'></Heading> */}
                     <h1>Company Token</h1>
                     <p>$SVT will be the company token representing the value of SageVerse L.l.c., its IP's and assets. This token will be given liquidity from the proceeds of digital asset sales, a seed round, ILO, private investors, advisors, and partners of the company.</p>
                 </div>
                 <img src={chart1}></img>
             </div>

             <div className="div_2">
                 <Heading content='$SVT (SageVerse Token)
'></Heading>
            <p>This is the token of our incubator, launchpad, and marketplace platform that will develop in-house projects and 
create collaborative partnerships with our peers in the art, gaming, and crypto sectors.</p>
    <div className="price_table">
        <div>
            <p className="price_text">Initial Market Cap:</p>
            <p className="price">$ 1M - $ 5M</p>
        </div>
        <div>
            <p className="price_text">Initial Maximum Supply: </p>
            <p className="price">$ 108 Million</p>
        </div>
        <div>
            <p className="price_text">Price</p>
            <p className="price">$0.04629</p>
        </div>
        <div>
            <p className="price_text">P2E</p>
            <p className="price">25%</p>
        </div>
        <div>
            <p className="price_text">DEV</p>
            <p className="price">20%</p>
        </div>
        <div>
            <p className="price_text">Team</p>
            <p className="price">20%</p>
        </div>
        <div>
            <p className="price_text">Marketing</p>
            <p className="price">20%</p>
        </div>
        <div>
            <p className="price_text">Public - Seed - Advisor</p>
            <p className="price">5% - 5% - 5%</p>
        </div>
    </div>



             </div>

             <div className="first_div">
                 <div className="text_part">
                     {/* <Heading content='Company Token'></Heading> */}
                     <h1>In-Game Tokens</h1>
                     <p>All currencies will be on POLYGON Block Chain</p>
                 </div>
                 <img src={chart1}></img>
             </div>


             <div className="div_2">
                 <Heading content='GOLD ($SVG)'></Heading>
            <p>Is the "Mortal" Tier Currency to be used at levels 1-49. There are no Mortal Tier assets above level 49.</p>
    <div className="price_table">
        <div>
            <p className="price_text">Initial Market Cap:</p>
            <p className="price">$500,000 to $1,000,000</p>
        </div>
        <div>
            <p className="price_text">Initial Maximum Supply: </p>
            <p className="price">36 Billion</p>
        </div>
        <div>
            <p className="price_text">Price</p>
            <p className="price"> $ 0.00001389- 0.000027</p>
        </div>
        <div>
            <p className="price_text">Lowest Price Before Buyback:</p>
            <p className="price">$0.000009</p>
        </div>
        <div>
            <p className="price_text">Max Price:</p>
            <p className="price">$ .0013</p>
        </div>
        <div>
            <p className="price_text">P2E - DEV</p>
            <p className="price">70% - 30%</p>
        </div>
        
    </div>


             
         </div>

         <div className="div_3">
             <img src={chart3}></img>
         <Heading content='JADE ($SVJ)  
'></Heading>
            <p>Is the "Earth" tier currency to be used at levels 39-99 There are no Earth tier assets above level 99.</p>
    <div className="price_table">
        <div>
            <p className="price_text">Initial Market Cap:</p>
            <p className="price">$5,000,000 to $10,000,000</p>
        </div>
        <div>
            <p className="price_text">Initial Maximum Supply: </p>
            <p className="price">360 million</p>
        </div>
        <div>
            <p className="price_text">Price</p>
            <p className="price"> $ .00135 - $ .0027</p>
        </div>
        <div>
            <p className="price_text">Max Price:</p>
            <p className="price">$.99</p>
        </div>
        <div>
            <p className="price_text">Lowest Price Before Buyback:</p>
            <p className="price">$.00027</p>
        </div>
        <div>
            <p className="price_text">P2E - DEV</p>
            <p className="price">80% - 20%</p>
        </div>
        
    </div>


         </div>


         <div className="div_3">
             <img src={chart2}></img>
         <Heading content='CRYSTAL ($SVC)  
'></Heading>
            <p>Is the "Heaven" tier currency to be used at levels 80-149 There are no Heaven tier digital assets above level 149.</p>
    <div className="price_table">
        <div>
            <p className="price_text">Initial Market Cap:</p>
            <p className="price">$50,000,000 to $100,000,000</p>
        </div>
        <div>
            <p className="price_text">Initial Maximum Supply: </p>
            <p className="price">36 million</p>
        </div>
        <div>
            <p className="price_text">Price</p>
            <p className="price"> $ 1.35 - $ 2.7</p>
        </div>
        <div>
            <p className="price_text">Max Price:</p>
            <p className="price">$9</p>
        </div>
        <div>
            <p className="price_text">Lowest Price Before Buyback:</p>
            <p className="price">$6.3</p>
        </div>
        <div>
            <p className="price_text">P2E - DEV</p>
            <p className="price">90% - 10%</p>
        </div>


        
    </div>


         </div>

         <p className="last_para">A variable percentage of all proceeds from all sources of revenue will go the liquidity of all In-game currency tokens<br></br> in various optimized ratios to ensure a consistent P2E platform as the "Community Treasury."<br></br>
$SVG will be the first in-game token released to public. Proceeds from the buy/sell tax of $SVG and "SageVerse" NFT sales will go to the liquidity pool and towards regular buybacks of $SVG, as well as token mints to ensure a robust and sustainable P2E economy.<br></br>
The use case for "buybacks and mints" is to stabilize the price of in-game currencies.<br></br>
All smart contracts are the property of SageVerse L.l.c and for the sake of operational purposes cannot be renounced.<br></br> Buybacks will be purchased with marketplace revenue (e.g sales , taxes etc)</p>

        </div>
        </div>
    )
}
export default Section7;