import React from "react";
import button from '../../images/button.png';
import './button.css';


const Button =(props)=>{
    return(
        <a href={props.href} target='blank'><img src={button} alt='button'/></a>
    )
}

export default Button;