import React from "react";
import Heading from "../generalComponent/Heading";
import Paragraph from "../generalComponent/Pargraph";
import './threeSteps.css';

const ThreeSteps =()=>{
    return(
 
    <div className="threeSteps">
      <div className="step">
          <Heading content='The first Martial Arts Fantasy MetaMMORPG'></Heading>
          <Paragraph content='SageVerse™ Is a F2P, P2E "community collaboration" based MMORPG 
 with player-owned digital assets and cryptocurrency-based rewards powered by the Polygon blockchain.'></Paragraph>
      </div>
      <div className="step">
          <Heading content='Play To Earn'></Heading>
          <Paragraph content='Sageverse™ will employ a diverse economic structure to maximize the P2E opportunities 
at all levels of engagement, from casual asset collector to hardcore gamer.'></Paragraph>
      </div>
      <div className="step">
          <Heading content='Mission & Values'></Heading>
          <Paragraph content='SageVerse L.l.c.s mission is to create a mutually lucrative, fun, and collaborative MetaMMORPG P2E
 experience through which community members can engage in as many P2E mechanisms as possible,
 while always looking to innovate as digital ownership and blockchain technology develops and
 integrates into the mainstream economic structure.'></Paragraph>
      </div>

    </div>
    )
}


export default ThreeSteps;