import React from "react";
import { Carousel} from 'react-bootstrap';
import './crouselComponent.css';

// import jade1 from './images/mixjade.png'
import jade1 from './images/jade1.png'
import jade2 from './images/jade2.png'
import jade3 from './images/jade3.png'
import jade4 from './images/jade4.png'
import jade5 from './images/jade5.png'
// import jade1 from './images/jade1.png'




const CarouselContainer=()=>{
    return(

        <Carousel indicators= 'false' className="crousel">
  <Carousel.Item>
    <img
      className="w-100"
      src={jade1}
      alt="First slide"
    />
    {/* <Carousel.Caption>
      <h3>First slide label</h3>
      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="w-100"
      src={jade2}
      alt="Second slide"
    />

    {/* <Carousel.Caption>
      <h3>Second slide label</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
  <Carousel.Item>
    <img
      className=" w-100"
      src={jade3}
      alt="Third slide"
    />

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>

  <Carousel.Item>
    <img
      className=" w-100"
      src={jade4}
      alt="Third slide"
    />

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>

  <Carousel.Item>
    <img
      className=" w-100"
      src={jade5}
      alt="Third slide"
    />

    {/* <Carousel.Caption>
      <h3>Third slide label</h3>
      <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
    </Carousel.Caption> */}
  </Carousel.Item>
</Carousel>

    )
}

export default CarouselContainer;


