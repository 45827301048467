import React from "react";
import './header.css';
import navImg from '../../images/navbar.png';
import Navigation from "./Navigaton";
// import { Button } from "react-bootstrap";
import Button from "../generalComponent/Button";
import BurgerMenu from "./BurgerMenu";
import BottomImg from "../generalComponent/BottomImg";
import headerVideo from './images/start.mp4';
import link from '../Footer/images/linkedin.png';
import twiter from '../Footer/images/twiter.png';
import mail from '../Footer/images/gmail.png';
import discord from './images/discord.png';

const Header = ()=>{
    // console.log(navImg)
    return(
        <div className="my_header">
          <video id="header_video"  autoPlay loop muted>
             <source src={headerVideo} type="video/mp4"/>
           </video>
         <div className="nav_bar">
            <div className="nav_img">
               <img src={navImg} alt='Nav_image' />
            </div>
            {/* <div className="nav_btn">
              <Button></Button>
            </div> */}

            <div className="nav_burger">
                <BurgerMenu></BurgerMenu>
                {/* <BurgerMenu  /> */}

            </div>
            

             <div className="nav_list">
               <Navigation></Navigation> 
             </div>
              
         </div>
         <div className="social_links header_links">

                    <a href="https://www.tiktok.com/@sageverse_nft" target="blank"><img src={link}></img></a>
                    <a href="https://twitter.com/SageverseMedia" target="blank"><img src={twiter}></img></a> 
                    <a href="https://discord.gg/RVrbQN9Aph" target="blank"><img src={mail}></img></a>
                    {/* <a href=""><img src={telegram}></img></a>
                    <a href=""><img src={fb}></img></a> */}
                   
                
                    
                </div>
                <div className="discord_icon">
                <a href="https://discord.gg/dxCaJ8tpXZ"><img src={discord}></img> </a>

            </div>
         <div className="bottom">
         
           <BottomImg></BottomImg>
           </div>
         
        </div>
    )

}

export default Header;