import React from "react";
import Button from "../generalComponent/Button";
import './section3.css';
import BottomImg from "../generalComponent/BottomImg";
import Video from './images/S3video.mp4'


const Section3=()=>{

    return(
        <div className="section3" id="story">
            <video id="background-video"  autoPlay loop muted>
             <source src={Video} type="video/mp4"/>
           </video>
            <div>
            <div className="main_content">
            
            <h1>A Sign of Tribulations to Come...</h1>
            <div className="scroll-container">
            <div className="scroll-text">
             <p>On the continent of StarFall, where martial arts are considered the most respected profession, might is virtue, and weakness is sin. Despite this being the case, the martial arts civilization of the StarFall continent has fallen far from its renaissance 3.6 billion years ago. According to ancient legend, a great cataclysm visited the continent and sundered the Heavenly Tao, rendering it incomplete. Since then, the martial arts civilization has clawed its way back to its current period, where one out of every ten million people is born with spiritual roots and the ability to cultivate "Qi." However, suddenly one evening, a world-shaking supernatural phenomenon occurred and the entire Continent of StarFall was blanketed in darkness for nine days. Since then, the Qi flow of StarFall has increasingly grown in density, ancient cultivation legacies have begun to surface, and signs of unrest seem to be developing behind the veil of peace and cooperation. The Great Ancestor of the Nine Divine Extremes Sect sacrificed one thousand years of his lifespan and the regression of a small realm in his cultivation in order to perceive the faintest, almost negligible glimpse of the continent's future, before coughing a mouthful blood and directly fainting. Upon his awakening, he directly made two decrees, the second of which was a summit of the Five Great Sects. The first, however, was to deploy the "Nine Divine Extremes Pavilions" and begin to gather and train the world's geniuses for the upcoming tribulation...</p>
            </div>
            </div>

            
            <Button href="https://sageverse.gitbook.io/sageverse/sageverse-tm-gameplay/story-thus-far..."></Button>
            
            
            </div>
            </div>
            <div className="bottom">
           <BottomImg></BottomImg>
           </div>
            
        </div>
    )
}


export default Section3;
