import React from "react";
import './section0.css'
import BottomImg from "../generalComponent/BottomImg";
// import ReactPlayer from "react-player";

import Video from './images/startvideo.mp4';


// import startVideo from './images/start.mp4'


const Section0=()=>{
    return(
        <>
        <div className="section0">
            <div className="background-video">

                
                {/* <ReactPlayer url={Video}  playing="true"></ReactPlayer> */}
            <video id="background-video1"  autoPlay loop muted>
             <source src={Video} type="video/mp4"/>
           </video>
            </div>


{/* Discord Icon adding ................... */}
         

  {/* Dsicord Iconn Ending .........................           */}
            
           <div className="bottom">
           <BottomImg></BottomImg>
           </div>
           


        </div>
{/* 
<div className="section01">
<div className="background-video">

<video id="background-video01"  autoPlay loop muted>
 <source src={startVideo} type="video/mp4"/>
</video>
</div>

<div className="bottom">
<BottomImg></BottomImg>
</div>



</div> */}

</>
    )
}

export default Section0;